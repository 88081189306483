import React, { useState } from "react";
import { Box, Button, TextField, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Импортируем иконку стрелки
import apiClient from "../apiClient";

const AddPotok = () => {
  const navigate = useNavigate();
  const theme = useTheme(); // Получаем текущую тему
  const colors = tokens(theme.palette.mode);
  const [potokName, setPotokName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validatePotokName = (name) => {
    const regex = /^[a-z0-9_]+$/; // Допускает только латинские буквы, цифры и _
    return regex.test(name);
  };

  const handleCreatePotok = async () => {
    if (potokName.trim()) {
        if (!validatePotokName(potokName)) {
            setErrorMessage("Название потока может быть в нижнем регистре и содержать только латинские буквы, цифры и символ подчеркивания. Пробелы и другие символы не допускаются.");
            return;
        }

        try {
            await apiClient(`/api/potoks/add/${potokName}`, {
                method: "POST",
            });

            alert(`Поток ${potokName} создан`);
            navigate("/potok");
        } catch (error) {
            console.error("Ошибка при создании потока:", error);
            alert("Не удалось создать поток. Проверьте подключение к интернету.");
        }
    }
  };



  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" mb="20px">
        <IconButton onClick={() => navigate('/potok')}>
          <ArrowBackIcon />
        </IconButton>
        <Header title="Создать Поток" subtitle="Добавление нового потока" />
      </Box>

      <Box mb="20px">
        <TextField
          fullWidth
          variant="outlined"
          label="Название потока"
          value={potokName}
          onChange={(e) => {
            setPotokName(e.target.value);
            setErrorMessage(""); // Сбрасываем сообщение об ошибке при изменении текста
          }}
          error={!!errorMessage} // Показываем ошибку, если она есть
          helperText={errorMessage} // Текст ошибки
        />
      </Box>

      <Button variant="contained" color="primary" onClick={handleCreatePotok}>
        Создать
      </Button>
    </Box>
  );
};

export default AddPotok;
