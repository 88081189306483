import React, { useEffect, useState } from "react"; 
import { Box, Typography, TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient";

const NPSAnalytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [npsData, setNpsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Функция для применения фильтра по дате
  const handleDateFilter = () => {
    const params = new URLSearchParams();
    if (startDate) params.append('start_date', startDate);
    if (endDate) params.append('end_date', endDate);
    
    apiClient(`/api/nps/potoks?${params.toString()}`)
      .then((data) => {
        setNpsData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error("Ошибка при получении данных NPS:", error));
  };

  // Получение данных при загрузке компонента
  useEffect(() => {
    apiClient(`/api/nps/potoks`)
      .then((data) => {
        setNpsData(data);
        setFilteredData(data);
      })
      .catch((error) => console.error("Ошибка при получении данных NPS:", error));
  }, []);

  return (
    <Box m="20px">
      <Header title="Отчет по NPS" subtitle="NPS по потокам" />

      {/* Фильтрация по дате */}
      <Box display="flex" gap="20px" mt="20px">
        <TextField
          label="Начальная дата"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="Конечная дата"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button onClick={handleDateFilter} variant="contained" color="primary">
          Применить фильтр
        </Button>
      </Box>

      <Box mt="50px" mx="50px" width="1000px" alignItems="center">
        {filteredData.length === 0 ? (
          <Typography>Нет данных для отображения</Typography>
        ) : (
          <Box sx={{ maxWidth: '400px', borderTop: `1px solid ${colors.blueAccent[500]}`, width: '100%' }}>
            {filteredData.map((potok) => (
              <Box
                key={potok.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p="10px"
                sx={{
                  cursor: "pointer",
                  borderBottom: `1px solid ${colors.blueAccent[500]}`,
                  borderLeft: `1px solid ${colors.blueAccent[500]}`,
                  borderRight: `1px solid ${colors.blueAccent[500]}`,
                  ':hover': { backgroundColor: colors.blueAccent[600] },
                }}
                onClick={() => navigate(`/analytics/nps/${potok.id}`)}
              >
                <Typography
                  variant="h3"
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    marginRight: '20px',
                    borderRight: `1px solid ${colors.blueAccent[500]}`,
                    pr: 2,
                  }}
                >
                  {potok.stream_name}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    pl: 2,
                  }}
                >
                  {potok.average_nps}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NPSAnalytics;
