import React, { useEffect, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"; // Импорт DataGrid
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient";

const NPSPotokDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [potokName, setPotokName] = useState("");
  const [expertsNpsData, setExpertsNpsData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams();
    if (startDate) params.append('start_date', startDate.toISOString().split('T')[0]);
    if (endDate) params.append('end_date', endDate.toISOString().split('T')[0]);
    
    apiClient(`/api/nps/potoks/${id}?${params.toString()}`)
      .then((data) => {
        setPotokName(data.stream_name);
        const expertsData = data.experts.map((expert) => ({
          id: expert.id,
          full_name: expert.full_name,
          average_nps: expert.average_nps,
          nps_count: expert.clients_count,
        }));
        setExpertsNpsData(expertsData);
      })
      .catch((error) => console.error("Ошибка при получении данных NPS экспертов:", error));
  }, [id, startDate, endDate]);

  // Определение колонок для DataGrid
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "full_name", headerName: "Имя эксперта", flex: 2 },
    { field: "average_nps", headerName: "Средний NPS", flex: 1 },
    { field: "nps_count", headerName: "Количество отзывов", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header 
        title={`Отчет по NPS - ${potokName}`} 
        subtitle="NPS по экспертам" 
        documentCount={`Найдено: ${expertsNpsData.length}`}
      />
      
      <Box display="flex" gap="20px" mb="20px">
        <TextField
          type="date"
          label="Дата начала"
          InputLabelProps={{ shrink: true }}
          value={startDate ? startDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setStartDate(e.target.value ? new Date(e.target.value) : null)}
        />
        <TextField
          type="date"
          label="Дата окончания"
          InputLabelProps={{ shrink: true }}
          value={endDate ? endDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setEndDate(e.target.value ? new Date(e.target.value) : null)}
        />
      </Box>
      
      <Box mt="20px" height="75vh" sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
      }}>
        <DataGrid
          rows={expertsNpsData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>
    </Box>
  );
};

export default NPSPotokDetails;
