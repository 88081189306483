import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient";

const ClientsAnalytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [statusData, setStatusData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchStatusData = async () => {
    try {
      const data = await apiClient("/api/reports/deal-status", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (data && Array.isArray(data)) {
        setStatusData(data);
      } else {
        console.error("Неверный формат данных:", data);
      }
    } catch (error) {
      console.error("Ошибка при получении данных о статусах:", error);
    }
  };

  useEffect(() => {
    fetchStatusData();
  }, []);

  const handleUpdateReport = () => {
    const params = new URLSearchParams();
    if (startDate) params.append('start_date', startDate);
    if (endDate) params.append('end_date', endDate);
    
    apiClient(`/api/reports/deal-status?${params.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(data => {
      if (data && Array.isArray(data)) {
        setStatusData(data);
      } else {
        console.error("Неверный формат данных:", data);
      }
    })
    .catch(error => {
      console.error("Ошибка при обновлении отчета:", error);
    });
  };

  return (
    <Box m="20px">
      <Header title="Отчет по статусам клиентов" subtitle="Отчет о статусах клиентов за выбранный период" />

      <Box mt="20px" display="flex" justifyContent="space-between" mb="20px">
        <TextField
          label="Дата начала"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="Дата окончания"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Button variant="contained" color="info" onClick={handleUpdateReport}>
          Обновить отчет
        </Button>
      </Box>

      <Box mt="20px">
        {statusData.map((status) => (
          <Box
            key={status.status_id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="10px"
            bgcolor={colors.primary[400]}
            mb="10px"
          >
            <Typography>{status.status_name}</Typography>
            <Typography>{status.clients_count}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ClientsAnalytics;
