import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import SendIcon from '@mui/icons-material/Send';
import apiClient from "../apiClient";

function Rassylka() {
  const [formData, setFormData] = useState({
    experts: false,
    clients: false,
    text: '',
    file: null,
  });
  const [result, setResult] = useState(null);
  const [fileError, setFileError] = useState(''); // Новое состояние для ошибки файла
  const theme = useTheme();
  const colors = tokens(theme.palette.mode) || {};

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === 'file' && files[0]) {
      const file = files[0];
      const maxSize = 10 * 1024 * 1024; // 10 MB

      if (file.size > maxSize) {
        setFileError('Файл превышает допустимый размер в 10 мегабайт');
        setFormData((prevData) => ({
          ...prevData,
          file: null,
        }));
        return;
      } else {
        setFileError('');
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  const data = new FormData();
  data.append('experts', formData.experts);
  data.append('clients', formData.clients);
  data.append('text', formData.text);

  if (formData.file) {
    data.append('file', formData.file);
  }

  try {
    const response = await apiClient('/api/send-rassylka', {
      method: 'POST',
      body: data,
    });

    if (response.ok) {
      // Если response уже содержит JSON данные
      setResult({ success: true, message: 'Рассылка успешно отправлена!' });
    } else {
      setResult({ success: true, message: 'Рассылка успешно отправлена!' });
    }
  } catch (error) {
    console.error('Ошибка отправки рассылки:', error);
    setResult({ success: false, message: error.message || 'Ошибка отправки рассылки' });
  }
};

  
  return (
    <Box 
      sx={{ 
        padding: '50px', 
        display: 'flex', 
        position: 'center',
        flexDirection: 'column', 
        alignItems: 'left',
        backgroundColor: colors.primary ? colors.primary[400] : theme.palette.background.default,
        marginLeft: '40px', 
        marginTop: '100px',
        maxWidth: '95%',
      }}
    >
      <Typography 
        variant="h2" 
        gutterBottom 
        sx={{
          textAlign: 'left',
          marginBottom: '30px',
          color: colors.primary ? colors.primary[100] : theme.palette.text.primary,
        }}
      >
        Рассылка
      </Typography>

      <FormControlLabel
        control={
          <Checkbox 
            name="experts" 
            checked={formData.experts}
            onChange={handleChange}
            sx={{
              color: colors.greenAccent ? colors.greenAccent[500] : theme.palette.secondary.main,
              '&.Mui-checked': {
                color: colors.greenAccent ? colors.greenAccent[700] : theme.palette.secondary.dark,
              },
            }}
          />
        }
        label="Эксперты"
      />

      <FormControlLabel
        control={
          <Checkbox 
            name="clients" 
            checked={formData.clients}
            onChange={handleChange}
            sx={{
              color: colors.greenAccent ? colors.greenAccent[500] : theme.palette.secondary.main,
              '&.Mui-checked': {
                color: colors.greenAccent ? colors.greenAccent[700] : theme.palette.secondary.dark,
              },
            }}
          />
        }
        label="Клиенты"
      />

      <TextField
        label="Текст для рассылки"
        name="text"
        value={formData.text}
        onChange={handleChange}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        margin="normal"
        sx={{
          marginBottom: '20px',
          color: colors.primary ? colors.primary[100] : theme.palette.text.primary,
        }}
      />

      <Box sx={{ marginBottom: '20px' }}>
        <label>
          Загрузить видео, файл, аудио:
          <input 
            type="file" 
            name="file" 
            accept="video/*,image/*,audio/*,.doc,.pdf,.txt,.zip,.rar,.7z" 
            onChange={handleChange}
            style={{ marginLeft: '10px', color: colors.primary ? colors.primary[400] : theme.palette.text.secondary }}
          />
          Файл не должен превышать допустимый размер в 250 кбайт
        </label>
        {fileError && (
          <Typography variant="body2" color="error" sx={{ marginTop: '5px' }}>
            {fileError}
          </Typography>
        )}
      </Box>

      <Button
        color='info'
        type="submit"
        variant="contained"
        onClick={handleSubmit}
        startIcon={<SendIcon />}
        sx={{
          alignSelf: 'flex-start', 
          padding: '8px 16px',
          fontSize: '14px',
          marginTop: "40px",
        }}
      >
        Сделать рассылку
      </Button>
      
      <Box sx={{ marginTop: "20px", fontSize: "14px", lineHeight: "1.5", color: theme.palette.text.primary }}>
        <p><strong>Основные теги для стилизации текста:</strong></p>
        <p><strong>Жирный текст</strong> = Используйте тег <code>&lt;b&gt;</code> или <code>&lt;strong&gt;</code> для выделения текста жирным шрифтом.<br />
          Пример: <code>&lt;b&gt;Это жирный текст&lt;/b&gt;</code> или <code>&lt;strong&gt;Это тоже жирный текст&lt;/strong&gt;</code></p>
        <p><strong>Курсив</strong> = Используйте тег <code>&lt;i&gt;</code> или <code>&lt;em&gt;</code> для курсивного оформления текста.<br />
          Пример: <code>&lt;i&gt;Это курсив&lt;/i&gt;</code> или <code>&lt;em&gt;Это тоже курсив&lt;/em&gt;</code></p>
        <p><strong>Подчёркнутый текст</strong> = Используйте тег <code>&lt;u&gt;</code> для подчёркивания текста.<br />
          Пример: <code>&lt;u&gt;Это подчёркнутый текст&lt;/u&gt;</code></p>
      </Box>

      {result && (
        <Typography
          variant="h6"
          sx={{
            marginTop: '20px',
            color: result.success ? (colors.greenAccent ? colors.greenAccent[500] : theme.palette.success.main) : (colors.redAccent ? colors.redAccent[500] : theme.palette.error.main),
          }}
        >
          {result.message}
        </Typography>
      )}
      
    </Box>
  );
}

export default Rassylka;
