const apiClient = (endpoint, options = {}) => {
  // Production URL
  const url = `${endpoint}`;

  // Development URL
  // const url = `http://localhost:8000${endpoint}`;

  console.log(`Запрос к API: ${url}`, options);  // Лог запроса

  return fetch(url, options)
    .then(response => {
      console.log(`Ответ от API (${url}):`, response);  // Лог ответа

      if (!response.ok) {
        console.log(`Полученные данные от API (${url}):`, response);  // Лог данных
        return response;
      }

      return response.json();
    })
    .then(data => {
      console.log(`Полученные данные от API (${url}):`, data);  // Лог данных
      return data;
    })
    .catch(error => {
      console.error(`Ошибка при запросе к API (${url}):`, error);  // Лог ошибки
      throw error;  // Перебрасываем ошибку, чтобы она могла быть обработана выше
    });
};

export default apiClient;
