import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, IconButton, useTheme } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient";
import { useParams, useNavigate } from "react-router-dom";

const mockTemplateData = {
  id: 1,
  name: "Mock Template",
  kz_text: "Mock Kazakh Text",
  ru_text: "Mock Russian Text",
  kz_buttons: [{ text: "Mock KZ Button 1" }, { text: "Mock KZ Button 2" }],
  ru_buttons: [{ text: "Mock RU Button 1" }, { text: "Mock RU Button 2" }],
  comment: `
    <strong>Основные теги для стилизации текста:</strong>
    <br /><br />
    <strong>Жирный текст</strong> = Используйте тег &lt;b&gt; или &lt;strong&gt; для выделения текста жирным шрифтом.<br />
    Пример: &lt;b&gt;Это жирный текст&lt;/b&gt; или &lt;strong&gt;Это тоже жирный текст&lt;/strong&gt;<br /><br />
    <strong>Курсив</strong> = Используйте тег &lt;i&gt; или &lt;em&gt; для курсивного оформления текста.<br />
    Пример: &lt;i&gt;Это курсив&lt;/i&gt; или &lt;em&gt;Это тоже курсив&lt;/em&gt;<br /><br />
    <strong>Подчёркнутый текст</strong> = Используйте тег &lt;u&gt; для подчёркивания текста.<br />
    Пример: &lt;u&gt;Это подчёркнутый текст&lt;/u&gt;
  `,
};

const escapeHtml = (unsafe) => {
  if (typeof unsafe !== 'string') {
    return '';
  }
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const VariablesEdit = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState(null);

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await apiClient(`/api/templates/${id}`);
        response.comment = response.comment || '';
        response.kz_buttons = response.kz_buttons || [];
        response.ru_buttons = response.ru_buttons || [];
        response.comment = escapeHtml(response.comment);
        setTemplateData(response);
      } catch (error) {
        console.error("Ошибка при получении данных шаблона:", error);
        mockTemplateData.comment = escapeHtml(mockTemplateData.comment);
        setTemplateData(mockTemplateData);
      }
    };

    fetchTemplateData();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedData = {
      kz_text: event.target.kz_text.value,
      ru_text: event.target.ru_text.value,
    };

    if (templateData.kz_buttons && templateData.kz_buttons.length > 0) {
      updatedData.kz_buttons = templateData.kz_buttons.map((_, index) => {
        const buttonId = `kz_button_${index + 1}`;
        return { text: event.target[buttonId]?.value || "" };
      });
    }

    if (templateData.ru_buttons && templateData.ru_buttons.length > 0) {
      updatedData.ru_buttons = templateData.ru_buttons.map((_, index) => {
        const buttonId = `ru_button_${index + 1}`;
        return { text: event.target[buttonId]?.value || "" };
      });
    }

    try {
      await apiClient(`/api/templates/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });
      alert("Шаблон успешно обновлен!");
    } catch (error) {
      console.error("Ошибка при обновлении шаблона:", error);
      alert("Ошибка при обновлении шаблона");
    }
  };

  if (!templateData) return <div>Загрузка...</div>;

  return (
    <Box m="20px">
      <IconButton onClick={() => navigate(-1)} sx={{ mb: "20px" }}>
        <ArrowBackIcon />
      </IconButton>
      <Header title="ИЗМЕНЕНИЕ ПЕРЕМЕННЫХ" subtitle="Измените текстовые поля ниже" />
      <p>Шаблон: {templateData.name}</p>
      <Box
        component="form"
        m="40px 0 0 0"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="20px"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="kz_text"
          label="KZ Text"
          variant="filled"
          multiline
          rows={4}
          defaultValue={templateData.kz_text}
          sx={{
            width: "100%",
            backgroundColor: colors.primary[400],
            "& .MuiFilledInput-root": {
              backgroundColor: colors.primary[500],
            },
          }}
        />

        <TextField
          id="ru_text"
          label="RU Text"
          variant="filled"
          multiline
          rows={4}
          defaultValue={templateData.ru_text}
          sx={{
            width: "100%",
            backgroundColor: colors.primary[400],
            "& .MuiFilledInput-root": {
              backgroundColor: colors.primary[500],
            },
          }}
        />

        {templateData.kz_buttons && templateData.kz_buttons.length > 0 && (
          <>
            <Typography variant="h6">KZ Buttons:</Typography>
            {templateData.kz_buttons.map((button, index) => (
              <TextField
                key={index}
                id={`kz_button_${index + 1}`}
                label={`KZ Button ${index + 1}`}
                variant="filled"
                defaultValue={button.text}
                sx={{
                  width: "100%",
                  backgroundColor: colors.primary[400],
                  "& .MuiFilledInput-root": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              />
            ))}
          </>
        )}

        {templateData.ru_buttons && templateData.ru_buttons.length > 0 && (
          <>
            <Typography variant="h6">RU Buttons:</Typography>
            {templateData.ru_buttons.map((button, index) => (
              <TextField
                key={index}
                id={`ru_button_${index + 1}`}
                label={`RU Button ${index + 1}`}
                variant="filled"
                defaultValue={button.text}
                sx={{
                  width: "100%",
                  backgroundColor: colors.primary[400],
                  "& .MuiFilledInput-root": {
                    backgroundColor: colors.primary[500],
                  },
                }}
              />
            ))}
          </>
        )}

        {templateData.comment && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: colors.primary[400],
              padding: "16px",
              borderRadius: "4px",
              color: theme.palette.text.primary,
              "& strong": {
                color: colors.greenAccent[500],
              },
            }}
            dangerouslySetInnerHTML={{ __html: templateData.comment }}
          />
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: colors.greenAccent[600],
            "&:hover": {
              backgroundColor: colors.greenAccent[700],
            },
            alignSelf: "center",
          }}
        >
          Изменить
        </Button>
      </Box>
    </Box>
  );
};

export default VariablesEdit;
