import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from "../apiClient"; // Assuming you have an API client

const ExpertsAnalytics = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expertsData, setExpertsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("registration_date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [totals, setTotals] = useState({
    totalClients: 0,
    totalSales: 0,
    averageConversion: 0,
    averageNps: 0,
  });

  // Fetch Experts Data
  const fetchExpertsData = async () => {
    try {
      const response = await apiClient("/api/nps/experts");
      setExpertsData(response);  // Устанавливаем данные в state
      calculateTotals(response);
      console.log(response)  // Calculate totals initially
    } catch (error) {
      console.error("Ошибка при получении данных экспертов:", error);
      setExpertsData([]);  // Set to empty array if fetching fails
    }
  };

  useEffect(() => {
    fetchExpertsData();
  }, []);

  useEffect(() => {
    if (expertsData.length) {
      sortExpertsData();
    }
  }, [expertsData, sortField, sortOrder]);

  const sortExpertsData = () => {
    const sorted = [...expertsData].sort((a, b) => {
      const dateA = new Date(a[sortField]);
      const dateB = new Date(b[sortField]);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    setExpertsData(sorted);
  };

  // Calculate totals whenever expertsData changes
  const calculateTotals = (experts) => {
    const totalClients = experts.reduce((sum, expert) => sum + expert.clients_received, 0);
    const totalSales = experts.reduce((sum, expert) => sum + expert.sales_made, 0);
    const averageConversion = experts.length ? (totalSales / totalClients) * 100 : 0;
    const averageNps = experts.length
      ? experts.reduce((sum, expert) => sum + expert.average_nps_score, 0) / experts.length
      : 0;

    setTotals({
      totalClients,
      totalSales,
      averageConversion: averageConversion.toFixed(2),
      averageNps: averageNps.toFixed(2),
    });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "full_name", headerName: "Эксперт", flex: 1 },
    { field: "clients_received", headerName: "Клиентов получено", flex: 1 },
    { field: "sales_made", headerName: "Продаж сделано", flex: 1 },
    { field: "conversion_rate", headerName: "Конверсия (%)", flex: 1 },
    { field: "average_nps_score", headerName: "Средний NPS", flex: 1 },
    { field: "language", headerName: "Язык", flex: 1 },
    { 
      field: "status", 
      headerName: "Статус", 
      flex: 0.5, 
      renderCell: (params) => (params.value ? "Активен" : "Неактивен")  // Correctly display the status
    },
    { field: "registration_date", headerName: "Дата регистрации", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header
        title="Отчет по Экспертам"
        subtitle="Информация по зарегистрированным экспертам"
        documentCount={`Найдено: ${expertsData.length}`}
      />

      <Box mb="20px">
        <TextField
          label="Дата начала"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <TextField
          label="Дата окончания"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <Button variant="contained" color="info" onClick={fetchExpertsData}>
          Обновить отчет
        </Button>
      </Box>
      
      {/* Totals Row */}
      <Box mt="20px" p="10px" bgcolor={colors.blueAccent[700]}>
        <Typography variant="h6">ИТОГО:</Typography>
        <Typography>Клиентов получено: {totals.totalClients}</Typography>
        <Typography>Продаж сделано: {totals.totalSales}</Typography>
        <Typography>Средняя конверсия: {totals.averageConversion} %</Typography>
        <Typography>Средний NPS: {totals.averageNps}</Typography>
      </Box>
      
      <Box
        m="0px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            cursor: "pointer",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={expertsData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ExpertsAnalytics;
