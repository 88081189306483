import React from "react";
import { Paper, Typography } from "@mui/material";
import { Draggable } from "@hello-pangea/dnd";

const Task = ({ client, index, expertInfo, formatDateTime, handleCardClick }) => {
  if (!client.contacts) {
    console.error(`No contacts for client: ${client._id}`); // Логируем, если нет контактов
  }

  return (
    <Draggable draggableId={String(client._id)} index={index}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            padding: "16px",
            margin: "8px 0",
            cursor: snapshot.isDragging ? "grabbing" : "grab",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.primary[400]
                : theme.palette.primary[200],
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            borderRadius: "6px",
            transition: "all 0.3s ease",
            border: (theme) =>
              theme.palette.mode === 'dark'
                ? '1px solid rgba(255, 255, 255, 0.1)'
                : '1px solid rgba(0, 0, 0, 0.1)',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: (theme) =>
                theme.palette.mode === 'dark'
                  ? '0px 4px 12px rgba(0, 0, 0, 0.5)'
                  : '0px 4px 12px rgba(0, 0, 0, 0.2)',
            },
            ...(snapshot.isDragging && {
              boxShadow: (theme) =>
                theme.palette.mode === 'dark'
                  ? '0px 8px 16px rgba(0, 0, 0, 0.6)'
                  : '0px 8px 16px rgba(0, 0, 0, 0.3)',
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark'
                  ? theme.palette.primary[300]
                  : theme.palette.primary[300],
            })
          }}
          onClick={() => handleCardClick(client._id)}
        >
          <Typography variant="body1">
            #{client._id}
          </Typography>
          <Typography variant="body1">
            {client.contacts || "Контакты не указаны"}
          </Typography>
          <Typography variant="body2">
            Дата и время: {formatDateTime(client.created_at)}
          </Typography>
          <Typography variant="body2">
            Из потока: {expertInfo.stream_name}
          </Typography>
          <Typography variant="body2">
            Ответственный: {expertInfo.name}
          </Typography>

        </Paper>
      )}
    </Draggable>
  );
};

export default Task;
