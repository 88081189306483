import React, { useState, useEffect } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { CopyAll, Delete } from "@mui/icons-material";
import Header from "../../components/Header";
import { mockDataPotok } from "../../data/mockData"; // Используем mock данные
import apiClient from "../apiClient";

const Potok = () => {
  const navigate = useNavigate();
  const theme = useTheme(); // Получаем текущую тему
  const colors = tokens(theme.palette.mode); // Получаем цвета из темы
  const [potoks, setPotoks] = useState([]);

  useEffect(() => {
    const fetchPotoks = async () => {
      try {
        const potokData = await apiClient("/api/potoks");

        // Запрашиваем количество участников для каждого потока через /api/potok/{id}/participants
        const potokDataWithParticipants = await Promise.all(
          potokData.map(async (potok) => {
            try {
              const response = await apiClient(`/api/potok/${potok._id}/participants`);
              
              // Извлечение количества участников из ответа
              const participantCount = response.participant_count || 0;
              
              return { ...potok, participantCount };
            } catch (error) {
              console.error(`Ошибка при получении количества участников для потока ${potok.name}:`, error);
              return { ...potok, participantCount: 0 }; // По умолчанию 0, если ошибка
            }
          })
        );
    
        setPotoks(potokDataWithParticipants);
      } catch (error) {
        console.error("Ошибка при получении данных потоков:", error);
        alert("Ошибка при получении данных, используется режим оффлайн.");
        setPotoks(mockDataPotok);
      }
    };

    fetchPotoks();
  }, []);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    alert("Ссылка скопирована в буфер обмена");
  };

  const handleActivate = async (potokId) => {
    try {
      await apiClient(`/api/potoks/${potokId}/activate`, {
        method: "POST",
      });
      alert(`Поток ${potokId} активирован`);
      setPotoks((prev) =>
        prev.map((potok) => (potok._id === potokId ? { ...potok, status: true } : potok))
      );
      // Переход на другую страницу только после завершения всех операций
      navigate('/potok');
    } catch (error) {
      console.error("Ошибка при активации потока:", error);
      alert("Не удалось активировать поток. Проверьте подключение к интернету.");
    }
  };
  
  const handleDeactivate = async (potokId) => {
    try {
      await apiClient(`/api/potoks/${potokId}/deactivate`, {
        method: "POST",
      });
      alert(`Поток ${potokId} деактивирован`);
      setPotoks((prev) =>
        prev.map((potok) => (potok._id === potokId ? { ...potok, status: false } : potok))
      );
      // Переход на другую страницу только после завершения всех операций
      navigate('/potok');
    } catch (error) {
      console.error("Ошибка при деактивации потока:", error);
      alert("Не удалось деактивировать поток. Проверьте подключение к интернету.");
    }
  };
  
  const handleDeletePotok = async (potokId) => {
    try {
      await apiClient(`/api/potoks/${potokId}`, {
        method: "DELETE",
      });
      alert(`Поток ${potokId} удален`);
      setPotoks((prev) => prev.filter((potok) => potok._id !== potokId));
      // Переход на другую страницу только после завершения всех операций
      navigate('/potok');
    } catch (error) {
      console.error("Ошибка при удалении потока:", error);
      alert("Не удалось удалить поток. Проверьте подключение к интернету.");
    }
  };

  const potokColumns = [
    { field: "_id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Поток", flex: 1 },
    { field: "participantCount", headerName: "Количество участников", flex: 1 },
    {
      field: "actions",
      headerName: "Действие",
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <IconButton onClick={() => handleCopyLink(params.row.link)}>
            <CopyAll sx={{ color: colors.greenAccent[500] }} />
          </IconButton>
          {params.row.status ? (
            <Button 
              onClick={() => handleDeactivate(params.row._id)} 
              sx={{ 
                color: colors.blueAccent[400],
                backgroundColor: colors.primary[600],
                '&:hover': {
                  backgroundColor: colors.blueAccent[500],
                },
              }}
            >
              Деактивировать
            </Button>
          ) : (
            <Button 
              onClick={() => handleActivate(params.row._id)} 
              sx={{ 
                color: colors.blueAccent[400],
                backgroundColor: colors.primary[600],
                '&:hover': {
                  backgroundColor: colors.blueAccent[500],
                },
              }}
            >
              Активировать
            </Button>
          )}
          <Button 
            onClick={() => handleDeletePotok(params.row._id)} 
            sx={{ 
              color: colors.redAccent[400],
              backgroundColor: colors.primary[600],
              '&:hover': {
                backgroundColor: colors.redAccent[500],
              },
            }}
          >
            Удалить
          </Button>
        </Box>
      ),
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/potok/${params.row._id}`);
  };

  return (
    <Box m="20px">
      <Header title="Управление Потоками" subtitle="Обзор потоков" />

      <Box mb="20px">
        <Button variant="contained" color="primary" onClick={() => navigate('/potok/add')}>
          Создать Поток
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom>Потоки</Typography>
      <Box height="60vh" mb="20px">
        <DataGrid 
          rows={potoks} 
          columns={potokColumns} 
          onRowClick={handleRowClick}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default Potok;
