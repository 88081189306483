import React, { useState, useEffect } from "react";
import { Box, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import apiClient from '../apiClient';

const Base = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("full_name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatDateTime = (dateTime) => {
    if (!dateTime) return "Нет данных";
    const date = new Date(dateTime);
    return date.toLocaleString('ru-RU', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit' 
    });
  };

  const generateUniqueId = (type, id) => {
    return `${type}-${id}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        console.log("Начинаем загрузку данных клиентов");
        const contactsData = await apiClient("/api/clients");
        console.log("Получены данные клиентов:", contactsData);

        console.log("Начинаем загрузку данных экспертов");
        const expertsData = await apiClient("/api/experts");
        console.log("Получены данные экспертов:", expertsData);

        const uniqueContacts = Array.from(new Map(contactsData.map(item => [item._id, item])).values());
        const uniqueExperts = Array.from(new Map(expertsData.map(item => [item._id, item])).values());

        const combinedData = [
          ...uniqueContacts.map(contact => ({
            ...contact,
            id: generateUniqueId('C', contact._id),
            type: "Клиент",
            created_at_formatted: formatDateTime(contact.created_at),
          })),
          ...uniqueExperts.map(expert => ({
            ...expert,
            id: generateUniqueId('E', expert._id),
            type: "Эксперт",
            created_at_formatted: formatDateTime(expert.created_at),
          }))
        ];

        setRows(combinedData);
      } catch (error) {
        console.error("Ошибка при получении данных API:", error);
        setError("Произошла ошибка при загрузке данных. Пожалуйста, попробуйте позже.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setRows(prevRows => applyFiltersAndSort(prevRows));
  }, [searchText, sortField, sortOrder]);

  const applyFiltersAndSort = (data) => {
    return data
      .filter((item) =>
        Object.entries(item).some(([key, value]) =>
          value != null && String(value).toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];
        if (aValue == null && bValue == null) return 0;
        if (aValue == null) return 1;
        if (bValue == null) return -1;
        if (sortOrder === "asc") {
          return String(aValue).localeCompare(String(bValue));
        } else {
          return String(bValue).localeCompare(String(aValue));
        }
      });
  };

  const handleRowClick = (params) => {
    const { id, type } = params.row;
    const actualId = id.split('-')[1];

    if (type === "Клиент") {
      navigate(`/client/${actualId}`);
    } else if (type === "Эксперт") {
      navigate(`/experts/${actualId}`);
    }
  };

  const columns = [
    { 
      field: "_id", 
      headerName: "ID", 
      flex: 0.4,
      valueGetter: (params) => params.row._id || 'Нет данных'
    },
    { 
      field: "full_name", 
      headerName: "Контакты", 
      flex: 2,
      valueGetter: (params) => {
        if (params.row.type === "Эксперт") {
          return params.row.full_name || 'Нет данных';
        }
        return params.row.contacts || 'Нет данных';
      }
    },
    { 
      field: "type", 
      headerName: "Тип", 
      flex: 1 
    },
    { 
      field: "phone_number", 
      headerName: "Номер телефона", 
      flex: 2,
      valueGetter: (params) => {
        if (params.row.type === "Эксперт") {
          return params.row.phone_number || 'Нет данных';
        }
        return params.row.contacts?.phone_number || 'Нет данных';
      }
    },
    { 
      field: "telegram_account", 
      headerName: "Телеграм аккаунт", 
      flex: 2,
      valueGetter: (params) => {
        if (params.row.type === "Эксперт") {
          return params.row.telegram_account || 'Нет данных';
        }
        return params.row.username || 'Нет данных';
      }
    },
    { 
      field: "created_at_formatted", 
      headerName: "Дата создания", 
      flex: 2 
    }
  ];

  if (isLoading) {
    return <Box m="20px">Загрузка данных...</Box>;
  }

  if (error) {
    return <Box m="20px">{error}</Box>;
  }

  const filteredRows = applyFiltersAndSort(rows);

  return (
    <Box m="20px">
      <Header
        title="Общая база данных"
        subtitle="Контакты и эксперты"
        documentCount={`Найдено: ${filteredRows.length}`}
      />

      <Box mb="20px">
        <TextField
          label="Поиск"
          variant="outlined"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <Select
          value={sortField}
          onChange={(event) => setSortField(event.target.value)}
          sx={{ marginRight: "20px" }}
        >
          {columns.map((column) => (
            <MenuItem key={column.field} value={column.field}>
              {column.headerName}
            </MenuItem>
          ))}
        </Select>
        <Select value={sortOrder} onChange={(event) => setSortOrder(event.target.value)}>
          <MenuItem value="asc">По возрастанию</MenuItem>
          <MenuItem value="desc">По убыванию</MenuItem>
        </Select>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            cursor: "pointer",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onRowClick={handleRowClick}
        />
      </Box>
    </Box>
  );
};

export default Base;
