import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import Task from "./Task";

const Column = ({ provided, clients, status, experts, streams, handleCardClick }) => {
  const getExpertInfo = (responsibleId) => {
    const expert = experts.find((exp) => exp._id === responsibleId);
    if (!expert) {
      return { name: "Не назначен", stream_name: "Поток не указан" };
    }

    const stream = streams.find((stream) => stream._id === expert.stream_id);
    const streamName = stream ? stream.name : "Поток не найден";

    return {
      name: expert.full_name,
      stream_name: streamName,
    };
  };

  const formatDateTime = (dateTime) => {
    if (!dateTime) return "Не указана";
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  return (
    <Box
      ref={provided.innerRef}
      {...provided.droppableProps}
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: 250,
        maxWidth: 300,
        width: "100%",
        marginRight: "10px",
        overflow: "hidden",
        boxSizing: "border-box",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? theme.palette.primary[500]
            : theme.palette.primary[50],
        borderRadius: "8px",
        padding: "16px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
        border: (theme) =>
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <Paper
        sx={{
          padding: 2,
          height: "600px", // Увеличили высоту контейнера
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.6)" // Более контрастный цвет
                : "rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: (theme) =>
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.8)" // Ярче при наведении
                : "rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        <Typography variant="h6" gutterBottom>
          {status.name} ({clients.length})
        </Typography>
        {clients.map((client, index) => {
          const expertInfo = getExpertInfo(client.responsible_id);
          return (
            <Task
              key={client._id}
              client={client}
              index={index}
              expertInfo={expertInfo}
              formatDateTime={formatDateTime}
              handleCardClick={handleCardClick}
            />
          );
        })}
      </Paper>
      {provided.placeholder}
    </Box>
  );
};

export default Column;
