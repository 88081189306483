import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import apiClient from '../apiClient';  // Импортируйте ваш API клиент

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("template_id");
  const [sortOrder, setSortOrder] = useState("asc");
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
  const fetchTemplates = async () => {
    try {
      const templates = await apiClient("/api/templates");
      const filteredData = templates.map(({ template_id, name, kz_text, ru_text }) => ({
        id: template_id,
        template_id,
        name: name || "Не указано",
        kz_text: kz_text || "Не указан",
        ru_text: ru_text || "Не указан",
      }));
      setTemplates(filteredData);
    } catch {
      setTemplates([
        {
          id: 1,
          template_id: 1,
          name: 'Template 1',
          kz_text: 'KZ Text 1',
          ru_text: 'RU Text 1',
        },
        // Другие локальные шаблоны
      ]);
    }
  };

  fetchTemplates();
}, []);

  const handleEdit = (template) => {
    console.log("Editing template:", template); // Логирование выбранного шаблона
    navigate(`/templates/edit/${template.template_id}`, { state: template });
  };

  const applyFiltersAndSort = (data = []) => {
    console.log("Applying filters and sorting to data:", data); // Логирование данных перед фильтрацией и сортировкой
    return data
      .filter((template) =>
        Object.values(template).some((value) =>
          value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortField] > b[sortField] ? 1 : -1;
        } else {
          return a[sortField] < b[sortField] ? 1 : -1;
        }
      });
  };

  const columns = [
    { field: "template_id", headerName: "ID", flex: 0.2, type: 'number' },
    { field: "name", headerName: "Имя", flex: 2 },
    { field: "kz_text", headerName: "Текст KZ", flex: 2 },
    { field: "ru_text", headerName: "Текст RU", flex: 2 },
    {
      field: "actions",
      headerName: "Действие",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button 
          variant="contained"
          color="secondary"
          onClick={() => handleEdit(params.row)}
        >
          Изменить
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Список Шаблонов" subtitle="Управление шаблонами сообщений" />
      
      <Box mb="20px">
        <TextField
          label="Поиск"
          variant="outlined"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          sx={{ marginRight: "20px" }}
        />
        <Select
          value={sortField}
          onChange={(event) => setSortField(event.target.value)}
          sx={{ marginRight: "20px" }}
        >
          {columns.map((column) => (
            <MenuItem key={column.field} value={column.field}>
              {column.headerName}
            </MenuItem>
          ))}
        </Select>
        <Select value={sortOrder} onChange={(event) => setSortOrder(event.target.value)}>
          <MenuItem value="asc">По возрастанию</MenuItem>
          <MenuItem value="desc">По убыванию</MenuItem>
        </Select>
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            cursor: "pointer",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={applyFiltersAndSort(templates)}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default Templates;
