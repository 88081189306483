import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = () => {
    const staticUsername = "deployuser"; // Ваш статичный логин
    const staticPassword = "Danyarproject1@"; // Ваш статичный пароль

    if (username === staticUsername && password === staticPassword) {
      localStorage.setItem("isAuthenticated", "true");
      navigate("/contacts");
    } else {
      setError("Неверный логин или пароль");
    }    
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      mt="100px" 
      bgcolor={colors.primary[500]} 
      p="20px"
      borderRadius="8px"
    >
      <Typography variant="h4" color={colors.grey[100]}>
        Вход
      </Typography>
      <TextField
        label="Логин"
        variant="outlined"
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{
          backgroundColor: colors.primary[400],
          color: colors.grey[100],
        }}
        InputLabelProps={{
          style: { color: colors.grey[100] },
        }}
      />
      <TextField
        label="Пароль"
        type="password"
        variant="outlined"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{
          backgroundColor: colors.primary[400],
          color: colors.grey[100],
        }}
        InputLabelProps={{
          style: { color: colors.grey[100] },
        }}
      />
      {error && <Typography color={colors.red[500]}>{error}</Typography>}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleLogin}
        sx={{
          mt: "20px",
          bgcolor: colors.blueAccent[600],
          color: colors.grey[100],
        }}
      >
        Войти
      </Button>
    </Box>
  );
};

export default Login;
