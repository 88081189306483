import React, { useEffect, useState, useRef, useReducer } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import Column from "./Column";
import apiClient from "../apiClient";
import { mockDataExperts } from "../../data/mockData";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CLIENTS":
      return { ...state, clients: action.payload };
    case "SET_EXPERTS":
      return { ...state, experts: action.payload };
    case "SET_STREAMS":
      return { ...state, streams: action.payload };
    default:
      return state;
  }
};

const KanbanBoard = ({ statuses, clients: initialClients }) => {
  const navigate = useNavigate();
  const kanbanContainerRef = useRef(null);
  const [showHiddenStatuses, setShowHiddenStatuses] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    clients: {},
    experts: [],
    streams: [],
  });

  // Сортировка статусов
  const sortedStatuses = statuses
    .filter((status) => (showHiddenStatuses ? true : ![5, 6].includes(status._id)))
    .sort((a, b) => a._id - b._id);

  useEffect(() => {
    const groupedClients = statuses.reduce((acc, status) => {
      acc[status._id] = initialClients.filter((client) => client.status === status._id);
      return acc;
    }, {});
    dispatch({ type: "SET_CLIENTS", payload: groupedClients });
  }, [initialClients, statuses]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [experts, streams] = await Promise.all([
          apiClient("/api/experts"),
          apiClient("/api/potoks"),
        ]);
        dispatch({ type: "SET_EXPERTS", payload: experts || mockDataExperts });
        dispatch({ type: "SET_STREAMS", payload: streams });
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    fetchData();
  }, []);

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceStatusId = source.droppableId;
    const destinationStatusId = destination.droppableId;

    if (sourceStatusId === destinationStatusId) {
      const reorderedCards = reorderCards(state.clients[sourceStatusId], source.index, destination.index);
      dispatch({
        type: "SET_CLIENTS",
        payload: { ...state.clients, [sourceStatusId]: reorderedCards },
      });
    } else {
      const sourceCards = state.clients[sourceStatusId];
      const destinationCards = state.clients[destinationStatusId];
      const { sourceCards: newSourceCards, destinationCards: newDestinationCards } = moveCard(
        sourceCards,
        destinationCards,
        source.index,
        destination.index
      );
      dispatch({
        type: "SET_CLIENTS",
        payload: {
          ...state.clients,
          [sourceStatusId]: newSourceCards,
          [destinationStatusId]: newDestinationCards,
        },
      });

      const movedClient = newDestinationCards[destination.index];
      try {
        await apiClient(`/api/clients/${movedClient._id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ status: destinationStatusId }),
        });
      } catch (error) {
        console.error("Ошибка обновления статуса клиента:", error);
      }
    }
  };

  useEffect(() => {
    if (kanbanContainerRef.current) {
      kanbanContainerRef.current.scrollLeft = 0;
    }
  }, []);

  return (
    <Box
      ref={kanbanContainerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden", // Убираем внешний скроллинг
        maxHeight: "75vh",
        maxWidth: "100%", // Гарантируем, что ширина не выйдет за границы экрана
        border: "1px solid #ccc",
        borderRadius: 1,
        p: 2,
        boxSizing: "border-box", // Учитываем внутренние отступы
      }}
    >
      <Button onClick={() => setShowHiddenStatuses((prev) => !prev)} variant="contained" sx={{ mb: 2 }}>
        {showHiddenStatuses ? "Скрыть архив" : "Показать архив"}
      </Button>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto", // Добавляем горизонтальный скроллинг для колонок
          overflowY: "hidden", // Отключаем вертикальный скроллинг
          height: "100%",
          width: "100%", // Гарантируем, что контейнер не выйдет за рамки
          flexGrow: 1,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Box
            sx={{
              display: "flex",
              minWidth: "100%", // Сохраняем минимум 100% ширины для контента
            }}
          >
            {sortedStatuses.map((status) => (
              <Droppable key={status._id} droppableId={String(status._id)}>
                {(provided) => (
                  <Column
                    provided={provided}
                    clients={state.clients[status._id] || []}
                    status={status || { name: 'Статус не найден' }}
                    experts={state.experts.length > 0 ? state.experts : [{ _id: 0, full_name: 'Эксперты не найдены' }]}
                    streams={state.streams.length > 0 ? state.streams : [{ _id: 0, name: 'Потоки не найдены' }]}
                    handleCardClick={(clientId) => navigate(`/client/${clientId}`)}
                  />
                )}
              </Droppable>
            ))}
          </Box>
        </DragDropContext>
      </Box>
    </Box>
  );

};

const reorderCards = (cards, startIndex, endIndex) => {
  const result = Array.from(cards);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const moveCard = (sourceCards, destinationCards, sourceIndex, destinationIndex) => {
  const sourceClone = Array.from(sourceCards);
  const destClone = Array.from(destinationCards);
  const [movedCard] = sourceClone.splice(sourceIndex, 1);
  destClone.splice(destinationIndex, 0, movedCard);

  return { sourceCards: sourceClone, destinationCards: destClone };
};

export default KanbanBoard;
