import React, { useState, useEffect } from "react";
import { Box, Button, Typography, List, ListItem, ListItemText, Badge, Divider, TextField, IconButton, InputAdornment } from "@mui/material";
import { AttachFile, Send } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import apiClient from '../apiClient';
import { mockConversations, mockDataContacts, mockDataExperts } from "../../data/mockData";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const Support = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await apiClient("/api/support/conversations");
        const data = await response.json();
        setConversations(data);
      } catch (error) {
        console.error("Ошибка при получении данных разговоров, использование локальных данных:", error);
        setConversations(mockConversations); // Use mock data in case of error
      }
    };

    fetchConversations();
  }, []);

  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);
    const telegramId = conversation.telegramId;

    // Search for the user in both clients and experts
    let user = mockDataContacts.find(contact => contact.id === telegramId) || 
               mockDataExperts.find(expert => expert.id === telegramId);

    if (!user) {
      try {
        const [contactsResponse, expertsResponse] = await Promise.all([
          apiClient("/api/contacts"),
          apiClient("/api/experts")
        ]);

        const [contactsData, expertsData] = await Promise.all([
          contactsResponse.json(),
          expertsResponse.json()
        ]);

        user = contactsData.find(contact => contact.id === telegramId) || 
               expertsData.find(expert => expert.id === telegramId);
      } catch (error) {
        console.error("Ошибка при поиске пользователя по Telegram ID:", error);
      }
    }

    if (user) {
      user.type = mockDataContacts.includes(user) ? "Клиент" : "Эксперт";
    }
    setUserDetails(user);
  };

  const handleSendMessage = async () => {
    if (!messageText && !selectedFile) return;

    const newMessage = {
      text: messageText,
      date: new Date().toISOString(),
      fromSupport: true,
      file: selectedFile ? selectedFile.name : null
    };

    try {
      await apiClient(`/api/support/conversations/${selectedConversation.id}/messages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newMessage),
      });

      setSelectedConversation(prev => ({
        ...prev,
        messages: [...prev.messages, newMessage],
      }));
      setMessageText("");
      setSelectedFile(null);
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error);
    }
  };

  const handleCloseConversation = async (conversationId) => {
    try {
      await apiClient(`/api/support/conversations/${conversationId}/close`, {
        method: "POST",
      });
      setConversations((prev) => prev.filter((conv) => conv.id !== conversationId));
      setSelectedConversation(null);
    } catch (error) {
      console.error("Ошибка при закрытии разговора:", error);
    }
  };

  const handleRowClick = () => {
    if (userDetails.type === "Клиент") {
      navigate(`/client/${userDetails.id}`);
    } else if (userDetails.type === "Эксперт") {
      navigate(`/experts/${userDetails.id}`);
    }
  };

  return (
    <Box m="20px">
      <Header title="Тех. поддержка" subtitle="Онлайн телеграм" />


      <Box display="flex">
        {/* Conversations List */}
        <Box width="30%" bgcolor={colors.primary[400]} p="10px" borderRadius="5px">
          <Typography variant="h6" mb="10px">Открытые чаты</Typography>
          <List>
            {conversations.map((conversation) => (
              <ListItem 
                key={conversation.id} 
                button 
                onClick={() => handleSelectConversation(conversation)}
              >
                <ListItemText 
                  primary={conversation.title} 
                  secondary={new Date(conversation.date).toLocaleString()} 
                />
                {conversation.isNew && <Badge color="secondary" badgeContent="Новое" />}
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Chat Window */}
        <Box width="70%" ml="20px">
          {selectedConversation ? (
            <Box bgcolor={colors.primary[500]} p="20px" borderRadius="5px">
              {userDetails && (
                <Box mb="20px">
                  <Typography variant="h6">{userDetails.full_name}</Typography>
                  <Typography variant="body2">ID: {userDetails.id}</Typography>
                  <Typography variant="body2">Телеграм: {userDetails.telegram_account}</Typography>
                  <Typography variant="body2">Телефон: {userDetails.phone_number}</Typography>
                  <Typography variant="body2">Тип: {userDetails.type}</Typography>
                  <Button color="warning" variant="contained" onClick={handleRowClick}>Перейти в карточку</Button>
                </Box>
              )}
              <Divider sx={{ mb: "20px" }} />
              <Box height="400px" overflow="auto" p="10px" borderRadius="5px">
                {selectedConversation.messages.map((message, index) => (
                  <Box 
                    key={index} 
                    mb="10px" 
                    alignSelf={message.fromSupport ? "flex-end" : "flex-start"}
                    bgcolor={message.fromSupport ? colors.greenAccent[400] : colors.primary[400]} 
                    p="10px" 
                    borderRadius="5px"
                    maxWidth="60%"
                    ml={message.fromSupport ? "auto" : 0}
                    mr={message.fromSupport ? 0 : "auto"}
                    color={message.fromSupport ? "#000000" : "#ffffff"}  // белый цвет текста
                  >
                    <Typography variant="body2">{message.text}</Typography>
                    {message.file && <Typography variant="body2">File: {message.file}</Typography>}
                    <Typography variant="caption" display="block" textAlign="right">{new Date(message.date).toLocaleString()}</Typography>
                  </Box>
                ))}
              </Box>
              <Box display="flex" mt="20px">
                <TextField 
                  variant="outlined" 
                  fullWidth 
                  placeholder="Напишите сообщение..." 
                  value={messageText} 
                  onChange={(e) => setMessageText(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label" >
                          <AttachFile />
                          <input 
                            type="file" 
                            hidden 
                            onChange={(e) => setSelectedFile(e.target.files[0])} 
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Button variant="contained" color='info' onClick={handleSendMessage} sx={{ ml: "10px" }}>
                  <Send />
                </Button>
              </Box>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => handleCloseConversation(selectedConversation.id)}
                sx={{ mt: "20px" }}
              >
                Закрыть беседу
              </Button>
            </Box>
          ) : (
            <Typography>Выберите чат для просмотра</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
