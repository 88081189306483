import React, { useState, useEffect, useMemo, useReducer } from "react";
import { Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import KanbanBoard from "./KanbanBoard";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "../apiClient";
import dayjs from "dayjs";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATUSES":
      return { ...state, statuses: action.payload };
    case "SET_CLIENTS":
      return { ...state, clients: action.payload, filteredClients: action.payload };
    case "SET_FILTERED_CLIENTS":
      return { ...state, filteredClients: action.payload };
    case "SET_SELECTED_COLUMNS":
      return { ...state, selectedColumns: action.payload };
    case "SET_EXPERTS":
      return { ...state, experts: action.payload };
    case "SET_VIEW":
      return { ...state, view: action.payload };
    case "SET_SEARCH_TEXT":
      return { ...state, searchText: action.payload };
    case "SET_SORT":
      return { ...state, sortField: action.payload.field, sortOrder: action.payload.order };
    default:
      return state;
  }
};

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const defaultColumns = [
    "id",
    "contacts",
    "username",
    "status",
    "responsible_id",
    "language",
    "created_at",
  ];

  useEffect(() => {
    localStorage.setItem("selectedColumns", JSON.stringify(defaultColumns));
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    statuses: [],
    clients: [],
    filteredClients: [],
    experts: [],
    selectedColumns: defaultColumns,
    view: "kanban",
    searchText: "",
    sortField: "created_at",
    sortOrder: "desc",
  });
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [selectedColumn, setSelectedColumn] = useState("contacts");

  const columns = useMemo(() => {
    const allColumns = [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "contacts",
        headerName: "Контакты",
        flex: 1,
        valueGetter: (params) => params.row.contacts || "",
      },
      {
        field: "username",
        headerName: "Телеграм",
        flex: 1,
        valueGetter: (params) => params.row.username || "",
      },
      {
        field: "language",
        headerName: "Язык",
        flex: 1,
        valueGetter: (params) => params.row.language || "",
      },
      {
        field: "status",
        headerName: "Статус",
        flex: 1,
        valueGetter: (params) => {
          const statusId = params.row.status;
          if (!statusId) return "Не указан";
          const status = state.statuses?.find((s) => s._id === statusId);
          return status?.name || "Статус не найден";
        },
      },
      {
        field: "responsible_id",
        headerName: "Ответственный",
        flex: 1,
        valueGetter: (params) => {
          const expertId = params.row.responsible_id;
          if (!expertId) return "Не назначен";
          const expert = state.experts?.find((e) => e._id === expertId);
          return expert?.full_name || "Эксперт не найден";
        },
      },
      {
        field: "created_at",
        headerName: "Создано",
        flex: 1,
        valueGetter: (params) =>
          params.row.created_at ? dayjs(params.row.created_at).format("DD.MM.YYYY HH:mm") : "",
      },
    ];

    return allColumns.filter((column) => state.selectedColumns.includes(column.field));
  }, [state.selectedColumns, state.statuses, state.experts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statuses, clients, experts] = await Promise.all([
          apiClient("/api/statuses"),
          apiClient("/api/clients"),
          apiClient("/api/experts"),
        ]);

        const formattedClients = clients.map((client) => ({
          ...client,
          id: client.id || client._id,
        }));

        dispatch({ type: "SET_STATUSES", payload: statuses });
        dispatch({ type: "SET_CLIENTS", payload: formattedClients });
        dispatch({ type: "SET_EXPERTS", payload: experts });
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    fetchData();
  }, []);

  const filteredAndSortedClients = useMemo(() => {
    let filtered = [...state.clients];

    if (dateRange.from && dateRange.to) {
      filtered = filtered.filter((client) => {
        const createdAt = new Date(client.created_at);
        return createdAt >= new Date(dateRange.from) && createdAt <= new Date(dateRange.to);
      });
    }

    if (state.searchText && selectedColumn !== "created_at") {
      const searchLower = state.searchText.toLowerCase();
      filtered = filtered.filter((client) => {
        const value = client[selectedColumn];
        if (!value) return false;
        
        if (selectedColumn === "status") {
          const status = state.statuses.find((s) => s._id === value);
          return status?.name.toLowerCase().includes(searchLower);
        }
        if (selectedColumn === "responsible_id") {
          const expert = state.experts.find((e) => e._id === value);
          return expert?.full_name.toLowerCase().includes(searchLower);
        }
        
        return String(value).toLowerCase().includes(searchLower);
      });
    }

    if (state.sortField) {
      filtered.sort((a, b) => {
        if (state.sortField === "created_at") {
          const dateA = new Date(a.created_at || 0);
          const dateB = new Date(b.created_at || 0);
          return state.sortOrder === "asc" 
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        }

        const fieldA = a[state.sortField] || "";
        const fieldB = b[state.sortField] || "";
        const direction = state.sortOrder === "asc" ? 1 : -1;
        return fieldA > fieldB ? direction : fieldA < fieldB ? -direction : 0;
      });
    }

    return filtered;
  }, [state.clients, state.searchText, state.sortField, state.sortOrder, dateRange, selectedColumn, state.statuses, state.experts]);

  useEffect(() => {
    dispatch({ type: "SET_FILTERED_CLIENTS", payload: filteredAndSortedClients });
  }, [filteredAndSortedClients]);

  return (
    <Box m="20px">
      <Header
        title="ВОРОНКА"
        subtitle="Состояние запросов"
        documentCount={`Найдено: ${state.filteredClients.length}`}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          dispatch({ type: "SET_VIEW", payload: state.view === "table" ? "kanban" : "table" })
        }
        sx={{ marginBottom: "20px" }}
      >
        {state.view === "table" ? "Вид канбаном" : "Вид таблицы"}
      </Button>

      <Box mb="20px" display="flex" alignItems="start" gap="20px">
        <Box>
          <Select
            value={selectedColumn}
            onChange={(e) => {
              setSelectedColumn(e.target.value);
              dispatch({ type: "SET_SEARCH_TEXT", payload: "" });
              if (e.target.value !== "created_at") {
                setDateRange({ from: null, to: null });
              }
            }}
          >
            {columns.map((column) => (
              <MenuItem key={column.field} value={column.field}>
                {column.headerName}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {selectedColumn === "created_at" ? (
          <Box display="flex" flexDirection="column" gap="10px">
            <Box display="flex" alignItems="center" gap="20px">
              <TextField
                label="От"
                type="datetime-local"
                value={dateRange.from || ""}
                onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="До"
                type="datetime-local"
                value={dateRange.to || ""}
                onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  if (dateRange.from && dateRange.to) {
                    const filtered = state.clients.filter((client) => {
                      const createdAt = new Date(client.created_at);
                      return (
                        createdAt >= new Date(dateRange.from) &&
                        createdAt <= new Date(dateRange.to)
                      );
                    });
                    dispatch({ type: "SET_FILTERED_CLIENTS", payload: filtered });
                  } else {
                    alert("Оба значения 'От' и 'До' должны быть выбраны.");
                  }
                }}
              >
                Применить
              </Button>
            </Box>
            <Box display="flex" gap="10px">
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                  })
                }
              >
                Сегодня
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().subtract(1, "day").startOf("day").toISOString(),
                    to: dayjs().subtract(1, "day").endOf("day").toISOString(),
                  })
                }
              >
                Вчера
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().startOf("week").toISOString(),
                    to: dayjs().endOf("week").toISOString(),
                  })
                }
              >
                Эта неделя
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().subtract(7, "day").startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                  })
                }
              >
                Последние 7 дней
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().startOf("month").toISOString(),
                    to: dayjs().endOf("month").toISOString(),
                  })
                }
              >
                Этот месяц
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().subtract(30, "day").startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                  })
                }
              >
                Последние 30 дней
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().startOf("year").toISOString(),
                    to: dayjs().endOf("year").toISOString(),
                  })
                }
              >
                В этом году
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: colors.grey[100],
                  borderColor: colors.grey[700],
                  "&:hover": {
                    borderColor: colors.grey[500],
                  },
                }}
                onClick={() =>
                  setDateRange({
                    from: dayjs().subtract(1, "year").startOf("day").toISOString(),
                    to: dayjs().endOf("day").toISOString(),
                  })
                }
              >
                За 1 год
              </Button>
            </Box>
          </Box>
        ) : (
          <Box display="flex" gap="20px" alignItems="center">
            <TextField
              label="Поиск"
              variant="outlined"
              value={state.searchText}
              onChange={(e) => dispatch({ type: "SET_SEARCH_TEXT", payload: e.target.value })}
            />
            <Box display="flex" alignItems="center" gap="10px">
              <Button
                variant="contained"
                onClick={() =>
                  dispatch({
                    type: "SET_SORT",
                    payload: {
                      field: selectedColumn,
                      order: state.sortOrder === "asc" ? "desc" : "asc",
                    },
                  })
                }
                startIcon={state.sortOrder === "asc" ? "↑" : "↓"}
              >
                Сортировка
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {state.view === "table" ? (
        <Box m="40px 0 0 0" height="75vh">
          <DataGrid
            rows={state.filteredClients}
            getRowId={(row) => row.id}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            onRowClick={(params) => {
              navigate(`/client/${params.row.id}`);
            }}
            sx={{
              "& .MuiDataGrid-root": { border: "none" },
              "& .MuiDataGrid-cell": { borderBottom: "none", cursor: "pointer" },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
            }}
          />
        </Box>
      ) : (
        <KanbanBoard statuses={state.statuses} clients={state.filteredClients} />
      )}
    </Box>

  );
};

export default Contacts;
