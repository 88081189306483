export const mockDataHistory = [
  {
    clientId: 1,
    history: [
      {
        timestamp: "2024-08-01T12:34:56Z",
        description: "Создан новый клиент",
      },
      {
        timestamp: "2024-08-05T08:23:45Z",
        description: "Изменен ответственный эксперт: Иван Иванов",
      },
      {
        timestamp: "2024-08-10T10:15:30Z",
        description: "Изменен статус на 'В процессе'",
      },
    ],
  },
  {
    clientId: 2,
    history: [
      {
        timestamp: "2024-08-02T09:20:00Z",
        description: "Создан новый клиент",
      },
      {
        timestamp: "2024-08-06T11:45:30Z",
        description: "Изменен статус на 'Закрыт'",
      },
    ],
  },
  // Добавьте больше записей по аналогии
];

export const mockDataContacts = [
  {
    id: 1,
    full_name: "John Doe",
    phone_number: "1234567890",
    telegram_account: "@johndoe",
    issue: "Issue example",
    responsible_id: 11,
    request_date: "2024-08-01T00:00:00Z",
    status: 2,
    nps_score: 9,
    attended_by_psychologist: true,
    service_language: "English",
    created_at: "2024-08-01T10:30:00Z",
    updated_at: "2024-08-15T14:45:00Z",
    attempt_count: 1,
    last_assigned_expert_id: 5,
  },
  {
    id: 2,
    full_name: "Jane Smith",
    phone_number: "9876543210",
    telegram_account: "@janesmith",
    issue: "Another issue",
    responsible_id: 12,
    request_date: "2024-08-03T11:15:00Z",
    status: 1,
    nps_score: 7,
    attended_by_psychologist: false,
    service_language: "Kazakh",
    created_at: "2024-08-03T11:30:00Z",
    updated_at: "2024-08-18T09:20:00Z",
    attempt_count: 2,
    last_assigned_expert_id: 7,
  },
  {
    id: 3,
    full_name: "Sam Brown",
    phone_number: "4567890123",
    telegram_account: "@sambrown",
    issue: "Some issue",
    responsible_id: 13,
    request_date: "2024-08-05T13:45:00Z",
    status: 3,
    nps_score: 8,
    attended_by_psychologist: true,
    service_language: "Russian",
    created_at: "2024-08-05T14:00:00Z",
    updated_at: "2024-08-20T16:30:00Z",
    attempt_count: 1,
    last_assigned_expert_id: 2,
  },
  {
    id: 4,
    full_name: "Alice Green",
    phone_number: "3216549870",
    telegram_account: "@alicegreen",
    issue: "Another example issue",
    responsible_id: 14,
    request_date: "2024-08-07T15:00:00Z",
    status: 4,
    nps_score: 10,
    attended_by_psychologist: false,
    service_language: "Turkish",
    created_at: "2024-08-07T15:30:00Z",
    updated_at: "2024-08-22T17:45:00Z",
    attempt_count: 3,
    last_assigned_expert_id: 9,
  },
  {
    id: 5,
    full_name: "Michael Johnson",
    phone_number: "1597534862",
    telegram_account: "@michaeljohnson",
    issue: "Example issue",
    responsible_id: 15,
    request_date: "2024-08-09T18:15:00Z",
    status: 5,
    nps_score: 6,
    attended_by_psychologist: true,
    service_language: "English",
    created_at: "2024-08-09T18:45:00Z",
    updated_at: "2024-08-24T19:00:00Z",
    attempt_count: 2,
    last_assigned_expert_id: 3,
  },
  // Добавлены еще 45 рандомных записей ниже
  {
    id: 6,
    full_name: "Emily Davis",
    phone_number: "8527419630",
    telegram_account: "@emilydavis",
    issue: "Another issue example",
    responsible_id: 11,
    request_date: "2024-08-11T20:30:00Z",
    status: 1,
    nps_score: 9,
    attended_by_psychologist: false,
    service_language: "Russian",
    created_at: "2024-08-11T20:45:00Z",
    updated_at: "2024-08-25T21:00:00Z",
    attempt_count: 3,
    last_assigned_expert_id: 1,
  },
  {
    id: 7,
    full_name: "David Clark",
    phone_number: "7531594826",
    telegram_account: "@davidclark",
    issue: "Sample issue",
    responsible_id: 12,
    request_date: "2024-08-13T22:45:00Z",
    status: 2,
    nps_score: 7,
    attended_by_psychologist: true,
    service_language: "Kazakh",
    created_at: "2024-08-13T23:00:00Z",
    updated_at: "2024-08-26T23:15:00Z",
    attempt_count: 1,
    last_assigned_expert_id: 4,
  },
  {
    id: 8,
    full_name: "Olivia Wilson",
    phone_number: "9513572648",
    telegram_account: "@oliviawilson",
    issue: "Example issue",
    responsible_id: 13,
    request_date: "2024-08-15T09:15:00Z",
    status: 3,
    nps_score: 8,
    attended_by_psychologist: false,
    service_language: "Turkish",
    created_at: "2024-08-15T09:30:00Z",
    updated_at: "2024-08-28T10:00:00Z",
    attempt_count: 2,
    last_assigned_expert_id: 5,
  },
  {
    id: 9,
    full_name: "Liam Martinez",
    phone_number: "4561237890",
    telegram_account: "@liammartinez",
    issue: "Another issue example",
    responsible_id: 14,
    request_date: "2024-08-17T11:00:00Z",
    status: 4,
    nps_score: 10,
    attended_by_psychologist: true,
    service_language: "English",
    created_at: "2024-08-17T11:15:00Z",
    updated_at: "2024-08-29T12:30:00Z",
    attempt_count: 1,
    last_assigned_expert_id: 6,
  },
  {
    id: 10,
    full_name: "Sophia Anderson",
    phone_number: "7894561230",
    telegram_account: "@sophiaanderson",
    issue: "Issue sample",
    responsible_id: 15,
    request_date: "2024-08-19T13:30:00Z",
    status: 5,
    nps_score: 6,
    attended_by_psychologist: false,
    service_language: "Kazakh",
    created_at: "2024-08-19T14:00:00Z",
    updated_at: "2024-08-31T15:45:00Z",
    attempt_count: 3,
    last_assigned_expert_id: 7,
  },
  // ...
  // Добавьте остальные записи с уникальными данными
  // ...
];



export const mockDataExperts = [
  { 
    id: 11, 
    full_name: "Арман Турсунов", 
    phone_number: 7012345678, 
    telegram_account: "@arman_tursunov", 
    clients_received: 50, 
    sales_made: 20, 
    conversion_rate: 40.0, 
    average_nps_score: 8, 
    registration_date: "2024-01-15", 
    stream_id: 2,
    language_type: "Русский", 
    status: true, 
    created_at: "2024-01-15T08:00:00Z" 
  },
  { 
    id: 12, 
    full_name: "Жанна Касымова", 
    phone_number: 7012345679, 
    telegram_account: "@zhanna_kasymova", 
    clients_received: 60, 
    sales_made: 25, 
    conversion_rate: 41.7, 
    average_nps_score: 7, 
    registration_date: "2024-02-10", 
    stream_id: 1,
    language_type: "Оба", 
    status: true, 
    created_at: "2024-02-10T09:30:00Z" 
  },
  { 
    id: 13, 
    full_name: "Ербол Садыков", 
    phone_number: 7012345680, 
    telegram_account: "@erbol_sadykov", 
    clients_received: 45, 
    sales_made: 18, 
    conversion_rate: 40.0, 
    average_nps_score: 9, 
    registration_date: "2024-03-05", 
    stream_id: 3,
    language_type: "Казахский", 
    status: true, 
    created_at: "2024-03-05T10:00:00Z" 
  },
  { 
    id: 14, 
    full_name: "Айгуль Аманова", 
    phone_number: 7012345681, 
    telegram_account: "@aigul_amanova", 
    clients_received: 30, 
    sales_made: 12, 
    conversion_rate: 40.0, 
    average_nps_score: 8, 
    registration_date: "2024-03-20", 
    stream_id: 4,
    language_type: "Оба", 
    status: true, 
    created_at: "2024-03-20T11:00:00Z" 
  },
  { 
    id: 15, 
    full_name: "Дана Байбекова", 
    phone_number: 7012345682, 
    telegram_account: "@dana_baybekova", 
    clients_received: 55, 
    sales_made: 22, 
    conversion_rate: 40.0, 
    average_nps_score: 7, 
    registration_date: "2024-04-10", 
    stream_id: 5,
    language_type: "Русский", 
    status: true, 
    created_at: "2024-04-10T12:00:00Z" 
  },
  // Добавьте больше экспертов, если нужно
];

export const mockDataPotok   = [
  { 
    id: 1, 
    stream_name: "Поток А", 
    status: true, 
    link: "t.me/donybot&start=PotokA" 
  },
  { 
    id: 2, 
    stream_name: "Поток Б", 
    status: true, 
    link: "t.me/donybot&start=PotokB" 
  },
  { 
    id: 3, 
    stream_name: "Поток В", 
    status: false, 
    link: "t.me/donybot&start=PotokC" 
  },
  { 
    id: 4, 
    stream_name: "Поток С", 
    status: true, 
    link: "t.me/donybot&start=PotokD" 
  },
  { 
    id: 5, 
    stream_name: "Поток Г", 
    status: false, 
    link: "t.me/donybot&start=PotokE" 
  },
  { 
    id: 6, 
    stream_name: "Поток Р", 
    status: true, 
    link: "t.me/donybot&start=PotokF" 
  },
  // Add more streams as needed
];

export const mockDataStatuses = [
  { id: 1, name: "Новый" },
  { id: 2, name: "Принят" },
  { id: 3, name: "Созвонился" },
  { id: 4, name: "Работаю" },
  { id: 5, name: "Консультировал не купил" },
  { id: 6, name: "Продал" },
];

export const mockConversations = [
  {
    id: 1,
    title: "Поддержка клиента №1",
    date: "2024-08-25T10:00:00Z",
    isNew: true,
    telegramId: 3, // Assuming telegram ID is a field
    messages: [
      { text: "Здравствуйте, у меня проблема с доступом к аккаунту.", date: "2024-08-25T10:01:00Z", fromSupport: false },
      { text: "Добрый день! Мы сейчас проверим ваш аккаунт.", date: "2024-08-25T10:05:00Z", fromSupport: true },
    ],
  },
  {
    id: 2,
    title: "Поддержка клиента №2",
    date: "2024-08-25T09:00:00Z",
    isNew: false,
    telegramId: 12, // Assuming telegram ID is a field
    messages: [
      { text: "Я не могу найти нужную информацию на сайте.", date: "2024-08-25T09:01:00Z", fromSupport: false },
      { text: "Пожалуйста, уточните, какую информацию вы ищете?", date: "2024-08-25T09:10:00Z", fromSupport: true },
    ],
  },
];

export const mockTemplateData = [
  {
    id: 1,
    name: "Mock Template",
    kz: "Mock Kazakh Text",
    ru: "Mock Russian Text",
    kz_button: "Mock KZ Button Text",
    ru_button: "Mock RU Button Text",
    text: `
      <strong>Основные теги для стилизации текста:</strong>
      <br /><br />
      <strong>Жирный текст</strong> = Используйте тег &lt;b&gt; или &lt;strong&gt; для выделения текста жирным шрифтом.<br />
      Пример: &lt;b&gt;Это жирный текст&lt;/b&gt; или &lt;strong&gt;Это тоже жирный текст&lt;/strong&gt;<br /><br />
      <strong>Курсив</strong> = Используйте тег &lt;i&gt; или &lt;em&gt; для курсивного оформления текста.<br />
      Пример: &lt;i&gt;Это курсив&lt;/i&gt; или &lt;em&gt;Это тоже курсив&lt;/em&gt;<br /><br />
      <strong>Подчёркнутый текст</strong> = Используйте тег &lt;u&gt; для подчёркивания текста.<br />
      Пример: &lt;u&gt;Это подчёркнутый текст&lt;/u&gt;
    `,
  }
]